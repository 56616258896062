import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../css/pages/veterans.scss"
import ImageSlider from "../components/ImageSlider/ImageSlider"

const SmileForVeteransPage = () => {
  return (
    <Layout language="en">
      <SEO
        title="Smiles For Veterans Program"
        description="Smiles For Veterans Program at The Regional Center for Dental Implants & Oral Surgery"
      />
      <div className="sfv">
        <div className="sfv__hero sfv__section">
          <img
            className="sfv__hero-img--desktop"
            src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/smiles-for-veterans-desktop-hero-image"
            alt="smiles for soliders banner"
          />
          <img
            className="sfv__hero-img--mobile"
            src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/smiles-for-veterans-mobile-hero-image"
            alt="smiles for soliders banner"
          />
        </div>

        <div className="sfv__body sfv__section">
          <div className="sfv__container small">
            <h1>SUPPORTING THOSE WHO SERVE</h1>

            <p>
              The Regional Center for Dental Implants & Oral Surgery team is
              proud to announce that this year’s Smiles For Veterans program
              recipient is Denny from Jonesborough.
            </p>
            <p>
              Denny, 69, is a retired Army veteran who currently lives with his
              son’s family, where he serves as the primary cook and caretaker.
              Despite being the family’s pillar, Denny struggles with his
              health, including COPD and a recent back issue requiring an MRI
              for a ruptured disk. In his application, Denny shared that he can
              barely eat due to his dental problems and has not smiled in years,
              often growing his mustache long to hide his teeth. He also
              expressed concern about embarrassing his three grandsons, who are
              in ROTC, and hopes that restoring his smile will allow him to
              engage with his family without shame. Denny believes that this
              opportunity would not only improve his quality of life but ease
              the burden on his family, who worry about his suffering.
            </p>
            <p>
              We hope that restoring Denny's smile will help him regain his
              confidence, improve his overall well-being, and bring him joy in
              his role as a loving grandfather.
            </p>
            <h2 style={{ fontSize: "24px" }}>FULL-ARCH RESTORATION</h2>
            <p>
              Denny will receive a full set of customized, artificial teeth at
              no cost. This tooth replacement solution is called full-arch
              restoration because it replaces a full dental arch of missing or
              failing teeth. Our team of experts will work closely with a
              restorative dentist to complete the treatment.{" "}
            </p>
          </div>
        </div>

        {/* <div className="sfv__section">
          <div className="sfv__container">
            <ImageSlider
              adaptiveHeight
              useDots
              images={[
                "RCOMS/DEV/smiles-for-veterans-recipient-2023-1",
                "RCOMS/DEV/smiles-for-veterans-recipient-2023-2",
                "RCOMS/DEV/smiles-for-veterans-recipient-2023-3",
                "RCOMS/DEV/smiles-for-veterans-recipient-2023-4"
              ]}
            />
          </div>
        </div> */}

        <div className="sfv__section">
          <div className="sfv__container small">
            <p>
              Follow this page,{" "}
              <a
                href="https://www.facebook.com/regionalcenterforoms/"
                title="Follow us on Facebook"
                target="_blank">
                Facebook
              </a>
              , and{" "}
              <a
                href="https://www.instagram.com/regionalcenterforoms/"
                title="Follow us on Instagram"
                target="_blank">
                Instagram
              </a>{" "}
              to watch Denny’s journey to a new smile!
            </p>

            <p>
              <i>
                Learn more about{" "}
                <Link
                  to="/procedures/dental-implants-johnson-city-tn/"
                  title="Learn more about dental implants">
                  dental implants
                </Link>{" "}
                and{" "}
                <Link
                  to="/procedures/full-arch-restoration-johnson-city-tn/"
                  title="Learn more about full-arch-restoration">
                  full-arch restoration
                </Link>{" "}
                to see how they can improve your oral health and confidence.
                Whether you need to replace one tooth or an entire arch of
                teeth, our team has a solution for you.
              </i>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SmileForVeteransPage
